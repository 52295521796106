import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextRenderer from './renderer';
import styles from './index.module.scss';

class Text extends Component {
  render() {
    const { text } = this.props;

    return (
      <div className={styles.container}>
        <TextRenderer text={text}/>
      </div>
    );
  }
}

Text.propTypes = {
  text: PropTypes.string.isRequired
}

Text.defaultProps = {
  text: ``
}

export default Text;
