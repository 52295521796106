import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Glance from '../glance';
import Text from '../text';
import styles from './renderer.module.scss';

class SectionsRenderer extends Component {
  render() {
    const { sections } = this.props;

    if (!sections || sections.length === 0) { return null; }

    const renderedSections = sections.map((section, index) => {
      const key = section.contentful_id + index;

      switch (section.__typename) {
        case 'ContentfulGlance':
          return <Glance key={key} title={section.title} logo={section.logo} description={section.description.description} backgroundImage={section.backgroundImage} buttonLabel={section.buttonLabel} buttonLink={section.buttonLink} color={section.color}/>
        case 'ContentfulText':
          return <Text key={key} text={section.text.text}/>
        default:
          return null;
      }
    });

    return (
      <div className={styles.renderer}>
        {renderedSections}
      </div>
    );
  }
}

SectionsRenderer.propTypes = {
  sections: PropTypes.array.isRequired
}

SectionsRenderer.defaultProps = {
  sections: []
}

export default SectionsRenderer;
