import React from "react";
import PropTypes from 'prop-types';

import Image from '../image';
import TextRenderer from '../text/renderer';
import styles from './index.module.scss';

class Glance extends React.Component {
  render() {
    const { logo, description, backgroundImage, buttonLabel, buttonLink, color } = this.props;

    return (
      <section className={styles.glance}>
        <div className={styles.infos} style={{ color: color }}>
          <h2 className={styles.logo}>
            <img src={logo.file.url} title={logo.title} alt={logo.description}/>
          </h2>
          <div className={styles.description}>
            <TextRenderer text={description}/>
          </div>
          {( (buttonLabel &&buttonLink) &&
            <a className={styles.button} href={buttonLink} target='_blank' rel="noopener noreferrer">{buttonLabel}</a>
          )}
        </div>
        {( backgroundImage &&
          <div className={styles.backgroundImage}>
            <Image image={backgroundImage}/>
          </div>
        )}
      </section>
    )
  }
}

Glance.propTypes = {
  logo: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  backgroundImage: PropTypes.object,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  color: PropTypes.string
}

Glance.defaultProps = {
  color: '#000'
}

export default Glance;
